import $ from 'jquery';

export default scrollAnchor;

export function scrollAnchor () {
  const url = window.location.href.split('#')[0];

  const href = $(this).attr('href');
  if (typeof href === 'undefined' || !href.match('^(' + url + ')?#.+$')) {
    return true;
  }

  const id = href.replace(url, '');
  if ($(id).length) {
    scrollTo(id, 500, 0);
  }

  return false;
}

export function scrollTo (id, speed, mOffset) {
  // +5 je kvuli firefoxu, kterej neumi jezdit spravne na kotvicky
  $('html,body')
    .stop()
    .animate({ scrollTop: $(id).offset().top - mOffset + 5 }, speed, function () {})
}
