import 'bootstrap';
import $ from 'jquery';
import { scrollAnchor } from './utils/scroll';

global.$ = global.jQuery = $;

require('lightgallery.js/dist/css/lightgallery.min.css');
require('lightgallery.js');

require('../sass/page.scss');

$(document).on('click', 'a:not(#smweb-panel a)', scrollAnchor);

window.addEventListener('re-render', function () {

  document.querySelectorAll('.gallery-default').
    forEach(gallery => lightGallery(gallery));
});

$(window).scroll(function () {
  // console.log('Scrolling ...');
});

$(document).ready(function () {
  window.dispatchEvent(new CustomEvent('re-render'));
});
